<template>
  <Head v-if="costMin">
    <title>
      Купить четырехкомнатную квартиру в новостройке на Ботаническом саду в
      Москве от застройщика | ЖК VERY
    </title>
    <meta
      name="description"
      :content="
        'Продажа новых четырехкомнатных квартир в ЖК VERY от застройщика ГК ОСНОВА на ул Ботаническая 29 в Москве (СВАО, р-н Марфино) рядом с метро Фонвизинская/Петровско-Разумовская по цене от ' +
        $filters.priceFormat(costMin)
      "
    />
    <meta
      property="og:title"
      content="Купить четырехкомнатную квартиру в новостройке на Ботаническом саду в Москве от застройщика | ЖК VERY"
    />
    <meta
      property="og:description"
      :content="
        'Продажа новых четырехкомнатных квартир в ЖК VERY от застройщика ГК ОСНОВА на ул Ботаническая 29 в Москве (СВАО, р-н Марфино) рядом с метро Фонвизинская/Петровско-Разумовская по цене от ' +
        $filters.priceFormat(costMin)
      "
    />
    <meta
      property="og:image"
      content="https://api.gkosnova.tech/public/storage/media/2022/2/1200/oXzorkK8sRMBrx0yMrKq191wHUQIZLqNza1j99ke.jpg"
    />
  </Head>

  <FlatsSearch
    h1="Четырехкомнатные квартиры"
    pre-rooms="4"
    @costMin="onCostMinEmits"
  >
    <template #text>
      <div class="text mt-5">
        <p>
          Ищете просторное и&nbsp;комфортное жилье премиум-класса? 4-комнатные
          квартиры в&nbsp;Марфино позволят вам жить в&nbsp;сердце огромного
          города и&nbsp;находиться в&nbsp;шаговой доступности
          от&nbsp;бизнес-районов и&nbsp;торговых центров. Но&nbsp;при этом
          наслаждаться потрясающими видами на&nbsp;Останкинскую башню
          и&nbsp;оранжерею Ботанического сада, а&nbsp;также перспективами
          Москвы.
        </p>
        <p>
          ЖК&nbsp;Very предлагает купить 4-комнатную квартиру
          у&nbsp;Ботанического сада и&nbsp;получить в&nbsp;подарок уникальную
          инфраструктуру комплекса, а&nbsp;также возможность
          за&nbsp;5&nbsp;минут дойти до&nbsp;Ботанического сада или
          за&nbsp;20&nbsp;&mdash; до&nbsp;ВДНХ и&nbsp;Останкинского парка.
        </p>
        <h2 class="h4">
          Что получает владелец квартиры в&nbsp;ЖК&nbsp;Very дополнительно
        </h2>
        <p>
          Четырехкомнатная квартира в&nbsp;Москве у&nbsp;Ботанического
          сада&nbsp;&mdash; это роскошное жилье, которое требует
          соответствующего окружения. Вряд&nbsp;ли проживающие в&nbsp;ней люди
          будут чувствовать себя комфортно, выходя в&nbsp;неухоженный двор
          и&nbsp;проводя по&nbsp;часу времени в&nbsp;поисках парковочного места.
        </p>
        <p>
          ЖК&nbsp;Very предлагает не&nbsp;просто квартиры, а&nbsp;жилое
          пространство, в&nbsp;котором любой человек будет чувствовать себя
          максимально комфортно. Этому способствуют несколько преимуществ:
        </p>
        <ol>
          <li>
            Дизайн общих помещений жилых домов разработан студией Asthetique.
            В&nbsp;оформлении использовано сочетание природных
            и&nbsp;инновационных материалов, что позволяет добиться необычного
            внешнего вида лобби. Такое оформление подчеркнет статусность
            владельца комфортной недвижимости в&nbsp;Москве.
          </li>
          <li>
            На&nbsp;территории расположен собственный фитнесс-центр
            и&nbsp;спортплощадки. Для поддержания себя в&nbsp;хорошей спортивной
            форме не&nbsp;нужно ехать в&nbsp;спортзал. Заниматься любыми
            активностями можно сразу у&nbsp;дома. А&nbsp;любители побегать под
            сенью деревьев могут сделать это в&nbsp;ботаническом саду.
          </li>
          <li>
            Жильцам доступны комфортные общественные пространства. Доминантой
            окружающего пространства выступает висячий мост-ручей, который
            заканчивается потрясающей смотровой площадкой, расположенной
            на&nbsp;высоте 3&nbsp;этажа. Спуститься с&nbsp;нее можно
            по&nbsp;крытой горке-тоннелю.
          </li>
          <li>
            В&nbsp;дополнение к&nbsp;квартире можно приобрести кладовые
            и&nbsp;паркинг. Нет необходимости захламлять квартиру нужными
            и&nbsp;не&nbsp;очень вещами, а&nbsp;также думать о&nbsp;месте
            парковки автомобиля. Попасть к&nbsp;местам хранения
            и&nbsp;в&nbsp;паркинг можно на&nbsp;лифте прямо с&nbsp;жилых этажей.
          </li>
          <li>
            За&nbsp;безопасностью и&nbsp;микроклиматом в&nbsp;ЖК следит система
            &laquo;Умный дом&raquo;. Это дает возможность учитывать пожелания
            владельцев при охлаждении воздуха и&nbsp;отоплении, а&nbsp;также
            контролировать обстановку для спокойствия жителей.
          </li>
        </ol>
        <h3 class="h4">Что находится рядом с&nbsp;комплексом</h3>
        <p>
          В&nbsp;пешей доступности от&nbsp;ЖК&nbsp;Very, где будет находиться
          ваша 4-комнатная квартира, находятся:
        </p>
        <ul>
          <li>
            Ботанический сад площадью в&nbsp;30&nbsp;га, где можно гулять пешком
            или ездить на&nbsp;велосипеде, а&nbsp;также отдыхать под сенью
            густых деревьев.
          </li>
          <li>
            Останкинский парк площадью в&nbsp;71&nbsp;га, на&nbsp;территории
            которого есть скейтпарк, площадки со&nbsp;скамейками, воркаут-зоны
            и&nbsp;многое другое.
          </li>
          <li>
            ВДНХ, с&nbsp;продуманной и&nbsp;разнообразной инфраструктурой для
            отдыха в&nbsp;выходной день.
          </li>
        </ul>
      </div>
    </template>
  </FlatsSearch>
</template>

<script>
import FlatsSearch from "@/components/FlatsSearch.vue";
import { Head } from "@vueuse/head";
export default {
  components: {
    Head,
    FlatsSearch,
  },

  data() {
    return {
      costMin: null,
    };
  },
  methods: {
    onCostMinEmits(costMin) {
      this.costMin = costMin;
    },
  },
};
</script>
